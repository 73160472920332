import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types/storeTypes'
import { defaultClient } from '../utils/defaultClient'

const initialState: IStore['client'] = defaultClient

export const clientInfoSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClientInfo: (state, action) => {
      return action.payload
    },
    updateClientInfo: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const { setClientInfo, updateClientInfo } = clientInfoSlice.actions

export default clientInfoSlice.reducer
