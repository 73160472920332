import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { IImage, IStore } from '../../../../types/storeTypes'
import { useGetStepData } from '../../../../hooks/useGetStepData'
import { useFormSession } from '../../../../hooks/useFormSession'
import { Loader } from '../../../loader'
import { useOfferItem } from '../../../../hooks/useOfferItem'
import { getCurrency } from '../../../../utils/getCurrency'
import { IconNoData } from '../../../../images/IconNoData'
import { DefaultButton } from '../../../defaultButton'
import { useOfferDocs } from '../../../../hooks/useOfferDocs'
import { ACQUIRED, CONSIGNMENT } from '../../../../utils/itemStatuses'
import { ClientShortInfoBlock } from '../../../formBlocks/clientShortInfoBlock'
import moment from 'moment'
import parse from 'html-react-parser'
import { IconClose } from '../../../../images/IconClose'
import { PhotoUploader } from '../../photoUploader'
import { IUploadSignatureData, useOfferAgreements } from '../../../../hooks/useOfferAgreements'
import { IconPdf } from '../../../../images/IconPdf'
import { IconDraw } from '../../../../images/IconDraw'
import { IconFromPhone } from '../../../../images/IconFromPhone'
import { SignatureModal } from '../../../modals/signatureModal'
import { LoadSignatureViaMobileQrCode } from '../../../modals/QRCodeModals/loadSignatureViaMobileQrCode'
import { v4 as uuidv4 } from 'uuid'
import { FORM_SUBMITTED, LOCAL_ACCESS_TOKEN, SESSION_TOKEN } from '../../../../utils/consts'
import { callApi } from '../../../../utils/callApi'
import { ApiRoutes } from '../../../../utils/apiRoutes'
import { showGlobalPopup } from '../../../../store/globalPopupSlice'
import { MessageModal } from '../../../modals/messageModal'
import { defaultClient } from '../../../../utils/defaultClient'

const tabs = [
  { value: 'purchase', title: 'Purchase Agreement' },
  { value: 'consignment', title: 'Consignment Agreement' },
  { value: 'offer', title: 'Offer' },
  { value: 'contract', title: 'Contract' },
]

const clientRequiredFields = [
  'first_name',
  'last_name',
  'email',
  'phone_no',
  'country',
  'province',
  'municipality',
  'address1',
  'postal_code',
  'email',
]

const allRequiredFields = [...clientRequiredFields]

export const SignatureStep = () => {
  const { t, i18n } = useTranslation()
  const offerInfo = useSelector((store: IStore) => store.offerInfo)
  const currentStep = useSelector((store: IStore) => store.currentStep)
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const client = useSelector((store: IStore) => store.client)
  const agreements = useSelector((store: IStore) => store.agreements)
  const { offerItems, offerInfoUploaded } = offerInfo
  const { stepsLoading, changeCurrentStep, getSixthStepData, getPaymentStepData } = useGetStepData()
  const { saveSession, stopCheckSession } = useFormSession()
  const { getOfferItemTitle } = useOfferItem()
  const [saveLoading, setSaveLoading] = useState(false)
  const {
    loadPdf,
    loadPdfLoading,
    purchaseContractLoading,
    loadPurchaseContract,
    consignmentContractLoading,
    loadConsignmentContract,
  } = useOfferDocs()
  const [selectedTab, setSelectedTab] = useState('')
  const { uploadSignature, deleteSignature, deleteSignatureLoading, uploadSignatureLoading } = useOfferAgreements()
  const [showSignatureModal, setShowSignatureModal] = useState(false)
  const [showQrCode, setShowQrCode] = useState(false)
  const [token, setToken] = useState<string>(uuidv4())
  const dispatch = useDispatch()
  const notes = appointmentInfo.intermediate_save?.decisionNotes || ''
  const [clientInfo, setStateClientInfo] = useState<IStore['client']>(defaultClient)
  const [buyer, setBuyer] = useState<number>(0)
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [isUnmounting, setIsUnmounting] = useState(false)
  const sessionData = useSelector((store: IStore) => store.sessionData)
  const sessionToken = sessionData.sessionId || sessionStorage.getItem(SESSION_TOKEN) || ''
  const isSpain =
    appointmentInfo.intermediate_save &&
    appointmentInfo.intermediate_save.office &&
    appointmentInfo.intermediate_save.office.country &&
    appointmentInfo.intermediate_save.office.country.toLowerCase() === 'es'
      ? true
      : false

  const shouldCheckClient = !offerItems.some((item) => [ACQUIRED].includes(item.status || ''))

  useEffect(() => {
    const newClient = { ...client }
    if (client.identity && client.identity.height) {
      newClient.height_type = client.identity.height.measure
      newClient.height_1 =
        client.identity.height.measure === 'Metric'
          ? client.identity.height.value.cm || 0
          : client.identity.height.value.ft || 0
      newClient.height_2 = client.identity.height.value.in || 0
    }
    setStateClientInfo(newClient)
  }, [client])

  useEffect(() => {
    if (appointmentInfo.intermediate_save) {
      const data = appointmentInfo.intermediate_save
      if (data.is_unmounting !== undefined) setIsUnmounting(data.is_unmounting)
    }
  }, [appointmentInfo.intermediate_save])

  useEffect(() => {
    if (appointmentInfo.intermediate_save && appointmentInfo.intermediate_save.buyer_id) {
      setBuyer(appointmentInfo.intermediate_save.buyer_id)
    } else if (appointmentInfo && appointmentInfo.buyer_id) {
      setBuyer(appointmentInfo.buyer_id)
    }
  }, [appointmentInfo, appointmentInfo.buyer_id])

  const checkForErrors = () => {
    let fields = allRequiredFields
    const errors = fields
      .map((field) => {
        if (field === 'buyer') return !buyer ? field : ''
        else if (field === 'email') {
          if (!clientInfo.email && (!clientInfo.phone_no || clientInfo.phone_no.length < 5)) return field
        } else if (field === 'phone_no') {
          if (!clientInfo.email && (!clientInfo.phone_no || clientInfo.phone_no.length < 5)) return field
        } else if (field === 'postal_code') {
          if (!clientInfo.postal_code && clientInfo.country !== 'HK') return field
        } else if (field === 'province') {
          if (!clientInfo.province && ['CA', 'US'].includes(clientInfo.country)) return field
        } else if (field === 'id_state') {
          if (!clientInfo.id_state && ['US'].includes(client.country)) return field
        } else if (!clientInfo[field as keyof IStore['client']]) return field

        return ''
      })
      .filter((error) => !!error)

    if (errors.length > 0) {
      setFormErrors(errors)
      return false
    }

    return true
  }

  useEffect(() => {
    if (clientInfo.id) checkForErrors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientInfo])

  const onSave = async () => {
    setSaveLoading(true)
    if (shouldCheckClient && !checkForErrors()) {
      setOpenErrorModal(true)
      setSaveLoading(false)
      return null
    }
    stopCheckSession()

    if (offerItems.some((item) => [ACQUIRED].includes(item.status || ''))) {
      const nextStep = 5
      await getPaymentStepData()
      await saveSession({ currentStep: nextStep, is_unmounting: isUnmounting })
      changeCurrentStep(nextStep)
      setSaveLoading(false)
    } else {
      try {
        await callApi({
          method: 'POST',
          url: `${ApiRoutes.formFinish}`,
        })
        const nextStep = 6
        await saveSession({ currentStep: nextStep, is_unmounting: isUnmounting })
        const notes = appointmentInfo.intermediate_save?.decisionNotes || ''
        sessionStorage.setItem(FORM_SUBMITTED, 'true')
        await getSixthStepData(notes)
        changeCurrentStep(nextStep)
      } catch (error) {
        console.log(error, 'error data')
        dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
      } finally {
        setSaveLoading(false)
      }
    }
  }

  const onBack = () => {
    changeCurrentStep(currentStep - 1)
  }

  useEffect(() => {
    if (!showQrCode) setToken(uuidv4())
  }, [showQrCode])

  const onSignedImagesUploaded = (images: IImage[]) => {
    const data: IUploadSignatureData = {
      files: images.map((image) => {
        return { url: image.url, name: image.name }
      }),
      agreement: selectedTab as IUploadSignatureData['agreement'],
      signature: '',
    }
    uploadSignature(data)
  }

  const purchaseAmount = useMemo(() => {
    return offerItems.reduce((a, b) => {
      if (b.status && [ACQUIRED].includes(b.status)) {
        return a + (b.price ? +b.price : 0)
      } else {
        return a + 0
      }
    }, 0)
  }, [offerItems])

  const offeredItemsAmount = useMemo(() => {
    return offerItems.reduce((a, b) => {
      if (b.status && ![ACQUIRED, CONSIGNMENT].includes(b.status)) {
        return a + (b.price ? +b.price : 0)
      } else {
        return a + 0
      }
    }, 0)
  }, [offerItems])

  const consignmentAmount = useMemo(() => {
    return offerItems.reduce((a, b) => {
      if (b.status && [CONSIGNMENT].includes(b.status)) {
        return a + (b.target_amount ? +b.target_amount : 0)
      } else {
        return a + 0
      }
    }, 0)
  }, [offerItems])

  const filteredTabs = useMemo(() => {
    let newTabs = [...tabs]
    if (!offerItems.some((item) => item.status === ACQUIRED)) {
      newTabs = newTabs.filter((tab) => tab.value !== 'purchase')
    }
    if (!offerItems.some((item) => item.status === CONSIGNMENT)) {
      newTabs = newTabs.filter((tab) => tab.value !== 'consignment')
    }
    if (!offerItems.filter((item) => item.status !== CONSIGNMENT && item.status !== ACQUIRED).length) {
      newTabs = newTabs.filter((tab) => tab.value !== 'offer')
    }
    if (!isSpain || !offerItems.some((item) => item.status && [CONSIGNMENT, ACQUIRED].includes(item.status))) {
      newTabs = newTabs.filter((tab) => tab.value !== 'contract')
    }

    return newTabs
  }, [offerItems, isSpain])

  useEffect(() => {
    if (filteredTabs[0]) setSelectedTab(filteredTabs[0].value)
  }, [filteredTabs])

  const filteredItems = useMemo(() => {
    if (selectedTab === 'purchase') {
      return offerItems.filter((item) => item.status === ACQUIRED)
    }
    if (selectedTab === 'consignment') {
      return offerItems.filter((item) => item.status === CONSIGNMENT)
    }
    if (selectedTab === 'offer') {
      return offerItems.filter((item) => item.status !== CONSIGNMENT && item.status !== ACQUIRED)
    }

    return []
  }, [offerItems, selectedTab])

  const signatureFile = useMemo(() => {
    if (selectedTab === 'purchase') {
      return agreements.purchase_form
    }
    if (selectedTab === 'consignment') {
      return agreements.consignment_agreement
    }
    if (selectedTab === 'offer') {
      return agreements.offer_form
    }

    return ''
  }, [agreements, selectedTab])

  const signatureAmount = useMemo(() => {
    if (selectedTab === 'purchase') {
      return getCurrency(purchaseAmount, appointmentInfo.intermediate_save?.currency)
    }
    if (selectedTab === 'consignment') {
      return getCurrency(consignmentAmount, appointmentInfo.intermediate_save?.currency)
    }
    if (selectedTab === 'offer') {
      return getCurrency(offeredItemsAmount, appointmentInfo.intermediate_save?.currency)
    }

    return ''
  }, [selectedTab, purchaseAmount, appointmentInfo.intermediate_save?.currency, consignmentAmount, offeredItemsAmount])

  const saveButtonTitle = offerItems.some((item) => [ACQUIRED].includes(item.status || ''))
    ? `${t('Save and Continue')} →`
    : t('Complete Appointment')

  return stepsLoading.includes(5) || stepsLoading.includes(3) || !offerInfoUploaded ? (
    <div className='w-full h-[70vh] flex justify-center items-center'>
      <Loader className='h-[100px]' />
    </div>
  ) : (
    <>
      <div className='flex flex-col gap-6'>
        <div className='flex w-full gap-2 justify-start overflow-auto custom_scroll pb-2'>
          {filteredTabs.map((tab) => {
            return (
              <button
                key={`items-tab-${tab.value}`}
                type='button'
                onClick={() => setSelectedTab(tab.value)}
                className={`flex justify-center items-center text-lg font-medium rounded-lg py-2 h-9 w-[280px] min-w-[200px] ${tab.value === selectedTab ? 'text-white bg-green-700' : ' text-zinc-600 bg-white'}`}
              >
                {t(tab.title)}
              </button>
            )
          })}
        </div>

        {selectedTab === 'contract' ? (
          <div className='flex gap-4'>
            {offerItems.some((item) => item.status === ACQUIRED) && (
              <div className='bg-white p-4 rounded-lg flex flex-col justify-center items-center'>
                <img
                  src='https://cdn.filestackcontent.com/auto_image/compress/resize=h:400,w:400,fit:crop/vx0nrr87RPuxj3ql120P'
                  alt=''
                  className='w-[200px] h-[200px]'
                />
                <div className='mt-2 mb-4'>{t('Contract of Sale')}.docx</div>

                <DefaultButton
                  title={t('Download file')}
                  icon='download'
                  onClick={() => loadPurchaseContract()}
                  classname='main_button flex items-center gap-1 !w-full !h-[42px]'
                  disabled={purchaseContractLoading}
                  loading={purchaseContractLoading}
                />
              </div>
            )}
            {offerItems.some((item) => item.status === CONSIGNMENT) && (
              <div className='bg-white p-4 rounded-lg flex flex-col justify-center items-center'>
                <img
                  src='https://cdn.filestackcontent.com/auto_image/compress/resize=h:400,w:400,fit:crop/vx0nrr87RPuxj3ql120P'
                  alt=''
                  className='w-[200px] h-[200px]'
                />
                <div className='mt-2 mb-4'>{t('Consignment Agreement')}.docx</div>

                <div className='flex w-full [&>div]:w-full'>
                  <DefaultButton
                    title={t('Download file')}
                    icon='download'
                    onClick={() => loadConsignmentContract()}
                    classname='main_button flex items-center gap-1 !w-full !h-[42px]'
                    disabled={consignmentContractLoading}
                    loading={consignmentContractLoading}
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            {!!filteredItems.length ? (
              <div className=''>
                <div className='text-base font-medium text-zinc-500 mb-2 flex justify-between px-3'>
                  <div className=' w-[60%]'>
                    <span className='mr-3'>#</span>
                    {t('Item Descriptions')}
                  </div>
                  <div className='flex justify-end w-[100px] min-w-[60px]'>{t('Price')}</div>
                </div>

                <div className=' rounded-lg bg-white flex flex-col divide-y divide-zinc-200 max-h-[50vh] overflow-y-auto custom_scroll'>
                  {filteredItems.map((item, index) => {
                    return (
                      <div
                        key={`${item.type}-${index}`}
                        className=' text-zinc-700 flex justify-between items-center px-3'
                      >
                        <div className='flex items-center w-[60%] min-h-[68px]'>
                          <div className='text-[22px] pb-1'>{index + 1}</div>
                          <div className=' text-base font-medium px-3 line-clamp-2'>{getOfferItemTitle(item)}</div>
                        </div>
                        <div className='flex'>
                          <div className='text-lg font-medium flex items-center justify-end w-[100px] min-w-[60px]'>
                            {item.status && item.status === CONSIGNMENT && item.target_amount
                              ? getCurrency(item.target_amount, appointmentInfo.intermediate_save?.currency)
                              : ''}
                            {item.status && item.status !== CONSIGNMENT && item.price
                              ? getCurrency(item.price, appointmentInfo.intermediate_save?.currency)
                              : ''}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            ) : (
              <div className='w-full bg-white rounded-lg h-[50vh] overflow-y-auto custom_scroll flex justify-center items-center'>
                <div className='flex flex-col justify-center items-center'>
                  <div className='text-[22px] text-zinc-800'>{t('Please add items')}</div>
                  <IconNoData />
                  <div className='text-lg text-zinc-400'>{t('No data')}</div>
                </div>
              </div>
            )}

            <div className='w-full flex flex-col md:flex-row justify-between items-start gap-6 md:gap-1 lg:gap-10 desktop:gap-20'>
              <div className='flex flex-col gap-4 w-full md:w-auto'>
                <ClientShortInfoBlock
                  client={clientInfo}
                  setClientInfo={setStateClientInfo}
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                  requiredFields={shouldCheckClient ? allRequiredFields : []}
                  isSignature
                  disableEdit={!shouldCheckClient}
                  isGlobalSave
                  buyer={buyer}
                />
              </div>

              <div className='flex flex-col gap-6 px-4 w-full'>
                <div className='flex justify-end text-lg font-medium text-zinc-700'>
                  {selectedTab === 'purchase'
                    ? t('Total Purchase Amount') +
                      ' ' +
                      getCurrency(purchaseAmount, appointmentInfo.intermediate_save?.currency)
                    : selectedTab === 'consignment'
                      ? t('Total Consigned Amount') +
                        ' ' +
                        getCurrency(consignmentAmount, appointmentInfo.intermediate_save?.currency)
                      : t('Total Offer Amount') +
                        ' ' +
                        getCurrency(offeredItemsAmount, appointmentInfo.intermediate_save?.currency)}
                </div>

                {selectedTab === 'offer' ? (
                  <>
                    {offerInfo.expiration_date && (
                      <div className='flex justify-end text-lg font-medium text-zinc-700'>
                        {t('Offer Valid Until')} {moment(offerInfo.expiration_date).format('MMMM DD, YYYY')}
                      </div>
                    )}
                    <div className='flex justify-end text-lg font-medium text-zinc-700'>
                      {t('Offer')} {`B${offerInfo.id}`}
                    </div>
                  </>
                ) : (
                  <>
                    <div className='text-base font-normal text-zinc-700 flex flex-col justify-end'>
                      {selectedTab === 'purchase'
                        ? parse(t('signature_purchase_agreement'))
                        : parse(t('signature_consignment_agreement'))}

                      {selectedTab === 'consignment' && isUnmounting ? (
                        <div className='mt-2 flex flex-col gap-1'>
                          {t('I authorize CIRCA to remove the diamond listed')}

                          <ul className='list-disc pl-5'>
                            <li>
                              <b>{t('If the consigned item is sold')}</b>,
                              {t('I will not be charged any fees or costs related to the GIA report.')}
                            </li>
                            <li>
                              <b>{t('If the consigned item is not sold')}</b>,
                              {t(
                                'I agree to pay for the cost of the GIA report and to indemnify CIRCA for any expenses incurred in this process.'
                              )}
                            </li>
                          </ul>

                          {t('By signing this agreement, I acknowledge and accept these terms.')}
                        </div>
                      ) : null}

                      {selectedTab === 'consignment' ? (
                        <div className='w-full flex items-center justify-end gap-2 pr-2'>
                          <input
                            type='checkbox'
                            name='send_marketing'
                            id='input-item-send_marketing'
                            checked={!!isUnmounting}
                            onChange={() => setIsUnmounting(!isUnmounting)}
                            className={`form-input min-w-[16px] w-[16px] h-[16px] accent-green-700`}
                            autoComplete='false'
                            disabled={!!signatureFile}
                          />
                          <label
                            htmlFor='input-item-send_marketing'
                            className='flex text-[15px] leading-5 text-zinc-800'
                          >
                            {t('Unmounting/GIA Cert')}
                          </label>
                        </div>
                      ) : null}
                    </div>

                    {signatureFile ? (
                      <div className='flex w-full items-center justify-end gap-4 lg:gap-12'>
                        <div className='text-base font-normal text-zinc-700 whitespace-nowrap flex justify-end'>
                          {t('Signed copy')}
                        </div>
                        <div className='flex-wrap gap-3 flex justify-end relative'>
                          {deleteSignatureLoading && (
                            <div className='absolute z-10 bg-zinc-500 opacity-20 rounded inset-0 flex justify-center items-center'>
                              <Loader className={`h-8 w-8 ml-2`} />
                            </div>
                          )}
                          <div className='flex items-center gap-2'>
                            <a
                              href={signatureFile}
                              target='_blank'
                              rel='noreferrer'
                              className='flex max-w-full max-h-full'
                            >
                              <IconPdf classname='h-[60px] w-auto' />
                            </a>
                            <div className='text-xs text-zinc-500 capitalize'>
                              {t(tabs.find((tab) => tab.value === selectedTab)?.title || '')}
                            </div>
                            <button
                              type='button'
                              onClick={() => deleteSignature(selectedTab as IUploadSignatureData['agreement'])}
                              disabled={deleteSignatureLoading}
                            >
                              <IconClose />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className='flex w-full items-center justify-end gap-4 lg:gap-12'>
                          <div className='text-base font-normal text-zinc-700 whitespace-nowrap flex justify-end'>
                            {t('Signature')}
                          </div>
                          <div className='flex gap-2 items-center relative'>
                            {uploadSignatureLoading && (
                              <div className='absolute z-10 bg-zinc-500 opacity-20 rounded inset-0 flex justify-center items-center'>
                                <Loader className={`h-8 w-8 ml-2`} />
                              </div>
                            )}

                            <div className='flex gap-2'>
                              <div className='w-[120px] hidden lg:flex'></div>
                              <button
                                type='button'
                                className='identification-image-upload-button !text-base lg:!text-lg !w-[136px] lg:!w-[150px]'
                                onClick={() => setShowSignatureModal(true)}
                              >
                                <IconDraw />
                                {t('Add signature')}
                              </button>
                              <button
                                type='button'
                                className='identification-image-upload-button !text-base lg:!text-lg !w-[136px] lg:!w-[150px]'
                                onClick={() => setShowQrCode(true)}
                              >
                                <IconFromPhone />
                                {t('From mobile')}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`flex w-full items-center  ${signatureFile ? ' justify-end gap-4 lg:gap-12' : 'justify-end gap-4 lg:gap-12'}`}
                        >
                          <div className='text-base font-normal text-zinc-700 whitespace-nowrap flex justify-end'>
                            {t('Upload signed copy')}
                          </div>
                          <div className='flex gap-2 relative flex-col items-start lg:flex-row lg:items-center'>
                            {uploadSignatureLoading && (
                              <div className='absolute z-10 bg-zinc-500 opacity-20 rounded inset-0 flex justify-center items-center'>
                                <Loader className={`h-8 w-8 ml-2`} />
                              </div>
                            )}
                            <DefaultButton
                              title={t('Print')}
                              onClick={() =>
                                loadPdf(
                                  selectedTab === 'purchase' ? 'purchase' : 'consignment',
                                  undefined,
                                  undefined,
                                  isUnmounting
                                )
                              }
                              classname='grey_button !w-[136px] lg:!w-[120px] !h-[50px] lg:!h-[53.6px]'
                              loading={loadPdfLoading}
                              disabled={saveLoading || loadPdfLoading}
                              icon='print'
                            />
                            <PhotoUploader
                              blockStyle='flex gap-2'
                              desktopButtonText='Choose file'
                              desktopButtonStyle='identification-image-upload-button !text-base lg:!text-lg !w-[136px] lg:!w-[150px]'
                              showQRCodeButton
                              qrcodeButtonText='From mobile'
                              qrcodeButtonStyle='identification-image-upload-button !text-base lg:!text-lg !w-[136px] lg:!w-[150px]'
                              callback={(image) => onSignedImagesUploaded(image)}
                              maxFiles={5}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}

        <div className='flex justify-between items-center mb-5 mt-12 md:mt-12 relative'>
          <DefaultButton
            title={`← ${t('Go Back')}`}
            onClick={onBack}
            classname='secondary_button min-w-[200px]'
            disabled={saveLoading || loadPdfLoading || uploadSignatureLoading}
          />

          {selectedTab === 'offer' && (
            <div className='flex items-center justify-center gap-4 absolute w-full md:w-auto top-[-60px] md:top-0 md:relative'>
              <DefaultButton
                title={t('Print')}
                onClick={() => loadPdf('offer', undefined, notes)}
                classname='grey_button'
                loading={loadPdfLoading}
                disabled={saveLoading || loadPdfLoading || uploadSignatureLoading}
                icon='print'
              />
            </div>
          )}

          <DefaultButton
            title={saveButtonTitle}
            onClick={onSave}
            classname='main_button min-w-[200px]'
            loading={saveLoading}
            disabled={
              saveLoading ||
              loadPdfLoading ||
              uploadSignatureLoading ||
              (filteredTabs.some((tab) => tab.value === 'purchase') && !agreements.purchase_form) ||
              (filteredTabs.some((tab) => tab.value === 'consignment') && !agreements.consignment_agreement) ||
              (shouldCheckClient && formErrors.length > 0)
            }
          />
        </div>
      </div>

      <SignatureModal
        showed={showSignatureModal}
        closeModal={() => setShowSignatureModal(false)}
        agreement={selectedTab as IUploadSignatureData['agreement']}
        isUnmounting={isUnmounting}
      />
      <LoadSignatureViaMobileQrCode
        visible={showQrCode}
        setVisible={setShowQrCode}
        qrCodeValue={`${window.location.origin}/${i18n.language}/add-signature/?signature_type=${selectedTab as IUploadSignatureData['agreement']}&amount=${signatureAmount}&upload_token=${token}&user_token=${sessionStorage.getItem(LOCAL_ACCESS_TOKEN)}&sessionToken=${sessionToken}&is_unmounting=${isUnmounting}`}
        token={token}
        signature_type={selectedTab as IUploadSignatureData['agreement']}
      />

      <MessageModal
        opened={openErrorModal}
        title='Error'
        text={t('Please fill in all required fields')}
        onClose={() => setOpenErrorModal(false)}
        type='error'
        errors={formErrors}
      />
    </>
  )
}
